

















import {Component, Inject, Vue, Watch} from 'vue-property-decorator';
import {HfCalendar} from '@/model/site/components.model';
import CalendarComponent from '@/components/shared/CalendarComponent.vue';
import {GameService} from '@/services/game.service';
import dayjs from 'dayjs';
import {FOCUS_DATE, SELECTED_LEAGUE_ID, SET_FOCUS_DATE, SITE_CONFIG} from '@/store/context.store';
import {Getter} from 'vuex-class';
import {validLeagueId} from '@/model/core/core-data.model';
import Loading from 'vue-loading-overlay';
import {SiteConfig} from '@/model/site/context.model';
import {isAnotherDay, DATE_FORMAT_ISO, TEMPORAL_NULL} from '@/utils/date.util';
import {Settings} from '@/model/site/settings.model';

@Component({
  components: {CalendarComponent, Loading},
})
export default class MatchdayCalendar extends Vue implements HfCalendar {

  @Inject('gameService') gameService!: GameService;
  @Inject('settings') settings!: Settings;

  @Getter(FOCUS_DATE) focusDate!: Date;
  @Getter(SELECTED_LEAGUE_ID) selectedLeagueId?: string;
  @Getter(SITE_CONFIG) siteConfig!: SiteConfig;

  calendar: HfCalendar = this;

  isLoading = false;
  showLoading = false;
  events: Date[] = [];
  selectableDates: Date[] = [];

  year!: number;
  month!: number;

  get calendarInline() {
    // $tablet / $desktop
    return this.settings.innerWidth > 935;
  }

  get minDate() {
    return new Date(2000, 7, 1);
  }

  /**
   * Today + one year
   */
  get maxDate() {
    const date = new Date();
    const year = date.getFullYear();
    date.setFullYear(year + 1, date.getMonth(), date.getDay());
    return date;
  }

  created() {
    this.month = this.focusDate.getMonth();
    this.year = this.focusDate.getFullYear();
    this.onMonthYearChange();
  }

  onDateChange(date: Date): void {
    if (isAnotherDay(date, this.focusDate)) {
      const newRoute = this.matchdayDateRoute(date);
      try {
        this.$router.push(newRoute);
      } catch (error) {
        this.$log.warn(`Error while navigating to ${JSON.stringify(newRoute)}`, error);
      }
    }
  }

  matchdayDateRoute(date: Date) {
    return {name: 'route-matchday-date', params: {dateStr: dayjs(date).format(DATE_FORMAT_ISO)}};
  }

  onMonthChange(monthIdx: number): void {
    this.month = monthIdx;
    this.onMonthYearChange();
  }

  onYearChange(year: number): void {
    this.year = year;
    this.onMonthYearChange();
  }

  onMonthYearChange(): void {
    // FIXME manchmal kommt das falsche Jahr!
    if (isNaN(this.year) || isNaN(this.month)) {
      Vue.$log.warn('year/month not valid', this.year, this.month);
      return;
    }
    this.isLoading = true;
    setTimeout(() => this.showLoading = this.isLoading, this.siteConfig ? this.siteConfig.loaderDelay : 0);
    // FIXME empty array -> no date selectable
    const dates = this.gameService.dates(this.year, this.month);
    const datesPerLeague = this.datesPerLeague();
    Promise.all([dates, datesPerLeague])
      .then((data: any[]) => {
        this.selectableDates.splice(0, this.selectableDates.length, ...data[0]);
        this.events.splice(0, this.events.length, ...data[1]);
        // no selectable dates -> no events
        if (this.selectableDates.length === 0) {
          // an empty array would allow values
          this.selectableDates = [TEMPORAL_NULL];
          this.events = [TEMPORAL_NULL];
        }
      })
      .finally(() => {
        this.isLoading = false;
        this.showLoading = false;
      });
  }

  @Watch(SELECTED_LEAGUE_ID)
  onChangedSelectedLeagueId() {
    this.isLoading = true;
    setTimeout(() => this.showLoading = this.isLoading, 0);

    this.datesPerLeague()
      .then((dates: Date[]) => this.events.splice(0, this.events.length, ...dates))
      .finally(() => {
        this.isLoading = false;
        this.showLoading = false;
      });
  }

  datesPerLeague() {
    return this.gameService.datesPerLeague(this.year, this.month, validLeagueId(this.selectedLeagueId));
  }

}
