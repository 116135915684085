
















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Box, HfPage} from '@/model/site/components.model';
import EventGames from '@/components/event/EventGames.vue';
import EventRanking from '@/components/event/EventRanking.vue';
import {Context, GameMode} from '@/model/site/context.model';
import {
  CONTEXT,
  FOCUS_DATE,
  MATCHDAY_CONFIG,
  SELECTED_LEAGUE_ID,
  SELECT_GROUP_ID,
  ALL_GROUP_ID,
  SELECT_LEAGUE_ID
} from '@/store/context.store';
import {Getter} from 'vuex-class';
import ContextLeagueSelection from '@/components/ui/ContextLeagueSelection.vue';
import {GAMES_AT_DATE, gamesByDate, LASTGAMES, LEAGUE_IDS, NEXTGAMES} from '@/store/data/getters';
import {GameInfo, IndexedGames, roundInfo, gameContentType} from '@/model/game/game.model';
import dayjs from 'dayjs';
import PanelBox from '@/components/ui/PanelBox.vue';
import MatchdayCalendar from '@/components/games/MatchdayCalendar.vue';
import {
  LOAD_CURRENT_GAMES,
  LOAD_GAMES_AT_DATE,
  LOAD_RANKING
} from '@/store/data/actions';
import {RankingId} from '@/model/ranking.model';
import Loading from 'vue-loading-overlay';
import CarouselGameDisplay from '@/components/games/CarouselGameDisplay.vue';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import {LeagueId} from '@/model/core/core-data.model';
import {RANKING_DATE_FORMAT} from '@/utils/date.util';
import MetaMixin from '@/mixins/meta.mixin';
import RouteMixin from '@/mixins/route.mixin';
import ShareThis from '@/components/shared/social/ShareThis.vue';
import MatchdayOverviewLink from '@/components/games/MatchdayOverviewLink.vue';
import SportLayout from '@/components/layout/SportLayout.vue';
import SideBoxes from '@/components/layout/SideBoxes.vue';
import SelectionTitle from '@/components/ui/SelectionTitle.vue';
import MatchdayGameDisplay from '@/components/games/MatchdayGameDisplay.vue';
import MatchdayGamePictures from '@/components/games/MatchdayGamePictures.vue';
import GameLinks from '@/components/event/GameLinks.vue';
import GameResult from '@/components/game/GameResult.vue';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import MobileMixin from '@/mixins/mobile.mixin';
import RefreshPageMixin from '@/mixins/refreshable-page.mixin';

Component.registerHooks([
  'beforeRouteEnter'
]);

@Component({
  components: {
    HfTeamLogo,
    GameResult,
    GameLinks,
    MatchdayGamePictures,
    MatchdayGameDisplay,
    SideBoxes,
    SportLayout,
    ShareThis,
    PanelBox,
    CarouselGameDisplay,
    ContextLeagueSelection,
    HfAdBox,
    EventRanking,
    EventGames,
    Loading,
    SelectionTitle,
  },

  mixins: [MetaMixin, RouteMixin, MobileMixin, RefreshPageMixin],
  metaInfo() {
    return this.metaInfo;
  },
})
export default class MatchdayPage extends Vue implements HfPage {

  @Getter(CONTEXT) context!: Context;
  @Getter(MATCHDAY_CONFIG) matchdayConfig!: any;

  @Getter(LASTGAMES) lastgames!: IndexedGames;
  @Getter(NEXTGAMES) nextgames!: IndexedGames;
  @Getter(GAMES_AT_DATE) gamesAtDate!: IndexedGames;
  @Getter(FOCUS_DATE) focusDate!: Date;
  @Getter(SELECTED_LEAGUE_ID) selectedLeagueId!: LeagueId;
  @Getter(LEAGUE_IDS) leagueIds!: LeagueId[];

  @Prop(String) dateStr!: string;

  isLoading = false;

  get headTitle() {
    return this.dateStr ? `${this.currentLeague} Spiele vom ${this.dateDisplay}` : `Aktuelle ${this.currentLeague} Spiele`;
  }

  get headDescription() {
    const games: GameInfo[] = [];
    const lastgames = this.lastLeagueGames;
    Object.keys(lastgames).forEach(date => {
      games.push(...lastgames[date]);
    });
    const gamesAtDate = this.leagueGamesAtDate;
    Object.keys(gamesAtDate).forEach(leagueId => {
      games.push(...gamesAtDate[leagueId]);
    });
    let desc = '';
    desc += games.filter((g: GameInfo) => g.result).map((g: GameInfo) => {
      return `${g.homeTeamNameShort} - ${g.awayTeamNameShort} ${g.result}`;
    }).join(' | ');
    if (desc) {
      desc = `Resultate: ${desc}`;
    } else {
      desc = 'Spiele: ' + games.map((g: GameInfo) => {
        return `${g.homeTeamName} - ${g.awayTeamName} ${g.time} Uhr`;
      }).join((' | '));
    }
    if (desc.length < this.matchdayConfig.metaDescriptionLength) {
      const titles = games.filter((g: GameInfo) => g.hasArticle).map((g: GameInfo) => g.articleTitle).join('. ');
      if (titles) {
        desc = `${desc} - ${titles}`;
      }
    }
    return desc;
  }

  get canonicalUrl() {
    return this.urlFromCurrentPath;
  }

  get currentLeague() {
    if (this.selectedLeagueId) {
      return this.$t(`leagueIdLong.${this.selectedLeagueId}`);
    }
    return '';
  }

  gameHeader(game: GameInfo) {
    return `${roundInfo(this, game)} • ${game.homeTeamNameShort} vs. ${game.awayTeamNameShort}`;
  }

  showPictures(game: GameInfo) {
    return game.hasPictures && this.maxPictures;
  }

  get maxPictures() {
    return this.matchdayConfig ? this.matchdayConfig.maxPictures || 0 : 0;
  }

  get dateObj(): Date {
    return this.dateStr ? new Date(this.dateStr) : new Date();
  }

  get dateDisplay(): string {
    return dayjs(this.dateObj).format('D. MMMM YYYY');
  }

  get rankingTitle() {
    const date = dayjs(this.dateObj);
    if (date.isValid()) {
      if (!date.isSame(new Date())) {
        return `Tabelle (${date.format(RANKING_DATE_FORMAT)})`;
      }
    }
    return 'Tabelle';
  }

  get boxes(): Box[] {
    const leagueSelection: Box = {
      component: ContextLeagueSelection,
      title: 'Liga',
      props: {showSingleElement: true, gapless: true, touchOnly: true}
    };

    const dateGames: Box = {
      title: this.dateDisplay,
      component: EventGames,
      props: {
        mode: GameMode.DATE,
        gamesAtDate: this.leagueGamesAtDate,
      },
    };
    const lastGames: Box = {
      title: 'Aktuelle Resultate',
      component: EventGames,
      props: {
        mode: GameMode.LAST,
        lastLeagueGames: this.lastLeagueGames,
      },
    };
    const nextGames: Box = {
      title: 'Die nächsten Spiele',
      component: EventGames,
      props: {
        mode: GameMode.NEXT,
        nextLeagueGames: this.nextLeagueGames,
      },
    };
    const ranking: Box = {
      component: EventRanking,
      props: {date: this.dateObj},
    };
    const ad: Box = {
      component: HfAdBox,
      props: {formatKey: 'rectangle', targets: ['desktop']},
    };
    const calendar: Box = {
      component: MatchdayCalendar,
      title: 'Datum'
    };
    let boxes = [leagueSelection, calendar, dateGames, lastGames, nextGames, ranking, ad];

    const currentGames: Box = {
      component: MatchdayOverviewLink,
    };
    if (this.dateStr) {
      boxes = [currentGames, ...boxes];
    }

    if (this.dateStr) {
      boxes = boxes.filter((box) => box !== nextGames && box !== lastGames);
    } else {
      boxes = boxes.filter((box) => box !== dateGames);
    }
    return boxes;
  }

  get displayGames(): IndexedGames {
    if (this.dateStr) {
      return this.leagueGamesAtDate;
    }
    let games = this.lastLeagueGames;
    if (!games || Object.keys(games).length === 0) {
      games = this.nextLeagueGames;
    }
    if (!games || Object.keys(games).length === 0) {
      Vue.$log.warn(`No games shown for League ${this.context.leagueId} @ ${this.dateStr}`);
    }
    return games;
  }

  get leagueGamesAtDate(): IndexedGames {
    return gamesByDate(this.gamesAtDate, this.context.leagueId);
  }

  get lastLeagueGames(): IndexedGames {
    return gamesByDate(this.lastgames, this.context.leagueId, this.matchdayConfig.games.last);
  }

  get nextLeagueGames(): IndexedGames {
    return gamesByDate(this.nextgames, this.context.leagueId, this.matchdayConfig.games.next);
  }

  @Watch(LEAGUE_IDS)
  onLeagueIdsChanged(newLeagueIds: LeagueId[]) {
    const leagueIdMatches = newLeagueIds.filter(leagueId => this.selectedLeagueId === leagueId).length > 0;
    if (!leagueIdMatches && newLeagueIds && newLeagueIds.length) {
      this.$store.commit(SELECT_LEAGUE_ID, newLeagueIds[0]);
    }
  }

  @Watch('dateStr')
  onDateStrChanged(newDateStr: string, oldDateStr: string) {
    if (newDateStr !== oldDateStr) {
      // do not load ranking as ranking should update itself
      this.$wait.start('matchdayPage');
      Promise.all(MatchdayPage.fetchData(this, this.dateObj, newDateStr, false))
        .then(() => {
          this.isLoading = false;
          // data is set in Vuex store
        })
        .catch((e: any) => Vue.$log.warn(e))
        .finally(() => this.$wait.end('matchdayPage'));
    }
  }

  created() {
    this.refreshPage();
  }

  refreshPage() {
    this.isLoading = true;
    this.$wait.start('matchdayPage');
    this.$store.commit(SELECT_GROUP_ID, ALL_GROUP_ID);
    // TODO loadRanking true/false ?!
    Promise.all(MatchdayPage.fetchData(this, this.dateObj, this.dateStr, true))
      .then(() => {
        // data is set in Vuex store
        this.isLoading = false;
      })
      .catch((e: any) => Vue.$log.warn(e))
      .finally(() => this.$wait.end('matchdayPage'));
  }

  static fetchData(vm: MatchdayPage, dateObj: Date, dateStr: string, loadRanking: boolean): Promise<any>[] {
    vm.isLoading = true;
    const empty = Promise.resolve();
    let dataPromises: any[];
    if (dateStr) { // important to use dateStr here (comes from router-parameter)
      const gamesAt = vm.$store.dispatch(LOAD_GAMES_AT_DATE, dateObj);
      dataPromises = [empty, empty, gamesAt];
    } else {
      const currentGames = vm.$store.dispatch(LOAD_CURRENT_GAMES);
      dataPromises = [currentGames, empty];
    }
    if (loadRanking && vm.context.leagueId) {
      dataPromises.push(vm.$store.dispatch(LOAD_RANKING, new RankingId(vm.context.leagueId, dateObj)));
    }
    return dataPromises;
  }

  contentTypeForGame(game: GameInfo) {
    return gameContentType(game);
  }

}
