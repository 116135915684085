











import {Component, Vue} from 'vue-property-decorator';
import {GamePicture} from '@/model/site/picture.model';
import {GameInfo} from '@/model/game/game.model';
import { Prop } from 'vue-property-decorator';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';
import {GameDetailService} from '@/services/game-detail.service';
import { Inject } from 'vue-property-decorator';
import {Carousel, Slide} from '@noim/vue-carousel';

@Component({
  components: {Carousel, Slide},
})
export default class MatchdayGamePictures extends Vue {

  @Inject('gameDetailService') gameDetailService!: GameDetailService;

  @Prop({required: true, type: Object}) game!: GameInfo;
  @Prop({required: true, type: Number}) maxPictures!: number;

  pictures: GamePicture[] = [];

  created() {
    if (this.game.hasPictures) {
      this.gameDetailService.pictures(this.game).then(
        (pictures: any) => {
          if (!pictures) {
            const msg = `No pictures for game ${this.game.gameId}`;
            Vue.$log.warn(msg);
            Sentry.captureMessage(msg, Severity.Warning);
          }
          this.pictures = pictures ? pictures : [];
        }
      );
    }
  }

  get pictureUrls(): string[] {
    if (this.pictures.length === 0) {
      return this.game.mainPictureUrl ? [this.game.mainPictureUrl] : [];
    } else {
      return this.pictures
        // do not allow portrait pictures (e.g. 181221rap)
        .filter((picture: GamePicture) => picture.height <= picture.width)
        .map((pic: GamePicture) => pic.resizeUrl || '')
        .filter((pic: string) => pic !== '')
        .slice(0, this.maxPictures);
    }
  }
}
