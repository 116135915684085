










































import {Component, Prop, Vue} from 'vue-property-decorator';
import {GameInfo} from '@/model/game/game.model';
import GameLinks from '@/components/event/GameLinks.vue';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import GameResult from '@/components/game/GameResult.vue';
import {GameContentType} from '@/model/site/components.model';

@Component({
  components: {GameResult, GameLinks, HfTeamLogo},
})
export default class MatchdayGameDisplay extends Vue {

  @Prop({required: true, type: Object}) game!: GameInfo;
  @Prop({required: true, type: String}) header!: string;
  @Prop({required: true, type: String}) contentType!: GameContentType;

}
