import Vue from 'vue';
import {Mixin} from 'vue-mixin-decorator';
import {ENABLE_REFRESHABLE} from '@/store/context.store';
import {HfPage} from '@/model/site/components.model';
import { refreshAds } from '@/utils/ad.util';

export interface HfRefreshablePage extends HfPage {
	refreshPage: () => void;
}

@Mixin
export default class RefreshPageMixin extends Vue implements HfRefreshablePage {

	doRefreshPage(): void {
		this.refreshPage();
		Vue.$ga.page(this.$route.fullPath);
		refreshAds();
	}

	refreshPage(): void {
		Vue.$log.warn('Should overwrite "refreshPage"');
	}

	created() {
		this.$root.$on('refresh-page', this.doRefreshPage);
		this.$store.commit(ENABLE_REFRESHABLE);
	}

	destroyed() {
		this.$root.$off('refresh-page', this.doRefreshPage);
	}

}
